<template>
    <div class="tech" ref="tech">
        <div class="ctn">
            <div class="outer" v-for="(item,i) in data" :key="i">
                <dl>
                    <dt :style="{'background-image':'url('+item.img.path+')'}" v-if="item.img.directMobile=='left'" class="img-left"></dt>
                    <dd :class="item.img.directMobile=='left'?'txt-left':'txt-right'" >
                        <h3>{{item.text.title}}</h3>
                        <h4>{{item.text.subTitle}}</h4>
                        <p v-for="(txt,j) in item.text.desc" :key="j">{{txt}}</p>
                    </dd>
                    <dt :style="{'background-image':'url('+item.img.path+')'}" v-if="item.img.directMobile=='right'" class="img-right"></dt>
                </dl>
            </div>
        </div>
    </div>
</template>
<script>
import $ from 'jquery'
export default {
    props:["data"],
    data(){
        return {
            
        }
    },
    mounted(){
        //this.setInitScroll();
    },
    methods:{
        setInitScroll(){
            for(let i=0;i<this.data.length;i++){
                if(this.data[i].img.directMobile=='left'){
                    $(this.$refs.tech).find('.outer').eq(i).scrollLeft(265);
                }
            };
        }
    }
}
</script>
<style scoped lang="less">
.tech{
    padding-top:50px;
    overflow: auto;
    .ctn{
        margin:0 auto;
        
        
        .outer{
            width:100%;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            overflow: auto;
        }
        dl{
            width:470px;
            height:200px;
            display: flex;
            margin-bottom:10px;
            dt{
               width:200px;
               height:200px; 
               background-repeat: no-repeat;
               background-position: center;
               background-size: cover;
               
            }
            .img-left{
                margin-right:10px;
            }
            .img-right{
                margin-left:10px;
            }
            dd{
                width:220px;
                height:160px;
                background:#f8f8f8;
                padding:20px;
                h3{
                    font-size:18px;
                    margin-bottom:5px;
                }
                h4{
                    font-size:14px;
                    margin-bottom:20px;
                }
                p{
                    margin-top:5px;
                }
            }
            .txt-right{
                text-align: right;
            }
        }
    }
}
</style>