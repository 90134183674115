<template>
    <div class="page">
        <div class="ctn">
            <div class="left">
                <div class="unit bg-img" :style="{'background-image':'url('+data.main.img+')'}">
                    <div class="wrapper">
                        <h1 class="fs20">{{data.main.title}}</h1>
                        <p class="fs16">{{data.main.subTitle}}</p>
                        <p class="main-txt">{{data.main.desc}}</p>
                    </div>
                </div>
            </div>
            <div class="right">
                <div class="unit bg-img" v-for="(item,i) in data.minor" :key="i" :style="{'background-image':'url('+item.img+')'}">
                    <div class="wrapper">
                        <h1 class="fs20">{{item.title}}</h1>
                        <p class="fs16">{{item.subTitle}}</p>
                        <p class="main-txt">{{item.desc}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props:["data"],
    data(){
        return {
            
        }
    }   
}
</script>
<style scoped lang="less">
.page{
    padding-top:50px;
    .ctn{
        
    }
    .unit{
        position: relative;
        
    }
    .left{
        height:300px;
        .unit{
            width:100%;
            height:100%;
            .wrapper{
                position: absolute;
                left:5%;
                bottom:20px; 
            }
        }
    }
    .right{
        .unit{
        .wrapper{
            padding:20px 5%;
            width:60%;
            background:-webkit-linear-gradient( right, rgba(255,255,255, 0),rgba(255,255,255, 0.8) );
        }
    }
        
    }
    .unit{
        .wrapper{
            

            .fs16{
                margin:5px 0 20px 0;
            }
        }
    }
}
</style>