<template>
    <div class="fee bg-img-bot" :style="{'background-image':'url('+data.img+')'}">
        <div class="ctn">
            <div class="head">
                <h1 class="fs30">{{data.title}}</h1>
                <h2 class="fs20">{{data.subTitle}}</h2>
                <p class="right">
                    <a class="blue-link" href="mqqwpa://im/chat?chat_type=wpa&uin=1771548541&version=1&src_type=web" target="_blank">QQ询价</a>
                    <a class="blue-link" href="tel:13040800634" target="_blank">手机询价</a>
                </p>
            </div>
            <div class="outer">
                <div class="content">
                    <div class="unit" v-for="(item,i) in data.items" :key="i">
                        <p class="tag">{{item.tag}}</p>
                        <p class="title">{{item.title}}</p>
                        <p class="main-txt desc" v-html="item.desc"></p>
                        <div class="price">
                            <p class="left"><span>￥</span><span class="num">{{item.price}}</span><span class="">{{item.unit}}</span></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    props:["data"],
    data(){
        return {
            
        }
    }
}
</script>
<style scoped lang="less">
.fee{
    
    .ctn{
       margin-top:50px;
        padding:30px 0 30px 0;
        .head{
            padding:0 5%;
            a{
                margin-right:10px;
                display: inline-block;
            }
        }
        h2{
            margin:5px 0 10px 0;
        }
        .outer{
            overflow: auto;
            
        }
        .content{
            width:540px;
            display: flex;
            justify-content: space-between;
            padding:30px 5% 0 5%;
            
            .unit{
                width:200px;
                background:rgba(255,255,255,0.9);
                padding: 20px 30px;
                .tag{
                    font-size:12px;
                    color:gray;
                }
                .title{
                    font-size:16px;
                    margin:10px 0 0 0;
                    font-weight: bold;
                }
                .desc{
                    margin:20px 0;
                    
                }
                .price{
                    
                    .num{
                        font-size:20px;
                        font-weight: bold;
                        padding-right:10px;
                    }
                    .right{
                        margin-top:10px;
                        
                    }
                }
            }
        }
    }
}
</style>