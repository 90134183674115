<template>
    <div class="slide">
        <div class="page">
            <div class="unit" v-for="(item,i) in data" :key="i" v-show="i==activeIdx">
                <div class="text">
                    <div class="ctn">
                        <p class="tag fs12 gray">{{item.tag}}</p>
                        <h1 class="fs20">{{item.title}}</h1>
                        <p class="desc main-txt">{{item.desc}}</p>
                    </div>
                </div>
                <div class="image bg-img"  
                    :style="{'background-image':'url('+item.img+')'}"></div>
                
            </div>
            
        </div>
        <div class="sliders">
            <span :class="['slider',activeIdx==i?'active':'']" 
                v-for="(val,i) in new Array(data.length)" :key="i"
                @click="activeIdx=i"></span>
        </div>
    </div>
</template>
<script>

export default {
    props:["data"],
    data(){
        return {
            activeIdx:0,
        }
    },
    mounted(){
        this.autoToggle();
    },
    methods:{
        autoToggle(){
            setInterval(()=>{
                if(this.activeIdx==this.data.length-1){
                    this.activeIdx=0;
                }else{
                    this.activeIdx++;
                }
                
            },4000);
        }
    }
}
</script>
<style scoped lang="less">
.slide{
    margin-top:50px;
    .sliders{
        display: flex;
        margin-top:10px;
        justify-content: center;
        .slider{
            width:15px;
            height:15px;
            background:#d9d9d9;
            margin:0 10px;
            border-radius: 50%;
        }
        .slider.active{
            background:#0071e3;
        }
    }
    .page{
        position: relative;
        width:100%;
        height:200px;
        overflow: auto;
        
        .unit{
            width:120%;
            display: flex;
        }
        .image{
            width:40%;
            height:200px;
        }
        .text{
            flex:1;
            height:140px;
            padding:30px 0;
            background:#f8f8f8;
            .ctn{
                padding:0 7%;
            }
            h1{
                font-size:20px;
                margin:5px 0 20px 0;
            }
        }
    }
}
</style>