<template>
    <div class="head bg-img" :style="{'background-image':'url('+data.img+')'}">
        <div class="content">
            <h1 class="fs45">{{data.title}}</h1>
            <p class="fs30">{{data.subTitle}}</p>
            <div class="outer">
                <div class="service">
                    <div :class="['ctn',i==activeIdx?'active':'']" 
                        v-for="(item,i) in data.items" :key="i"
                        @click="toggle(i)">
                        <dl>
                            <dd class="icon"><Icons :name="item.icon" height="25" width="25" color="#0071e3"/></dd>
                            <dd class="name fs16">{{item.title}}</dd>
                            <dd class="desc fs12">{{item.subTitle}}</dd>
                        </dl>
                        <div class="cover"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {EventBus} from '@/bus/index.js'
import Icons from '@/components/common/Icons.vue'
export default {
    components:{
        Icons
    },
    props:["data"],
    data(){
        return {
            activeIdx:0,
        }
    },
    mounted(){
        //this.autoToggle();
    },
    methods:{
        toggle(i){
            this.activeIdx=i;
            EventBus.$emit('headToggle',i);
        },
        autoToggle(){
            setInterval(()=>{
                if(this.activeIdx==this.data.length-1){
                    this.activeIdx=0;
                }else{
                    this.activeIdx++;
                }
                
            },2500);
        }
    }
}
</script>
<style scoped lang="less">
.head{
    .content{
        padding:30px 0;
        h1{
            text-align: center;
            color:white;
        }
        >p{
            text-align: center;
            margin:0 0 20px 0;
            color:white;
        }
        .outer{
            width:90%;
            margin:0 5%;
            overflow: auto;
        }
        .service{
            display: flex;
            margin:0 auto;
            width:600px;
            .ctn{
                width:150px;
                height:100px;
                position:relative;
                margin:1px;
                cursor: pointer;
                .cover{
                    width:100%;
                    height:100%;
                    position:absolute;
                    top:0;
                    left:0;
                    background:rgb(255,255,255);
                    opacity:0.7;
                }
            }
            .ctn.active{
                .cover{
                    opacity:0.9;
                }
            }
            dl{
                margin:15px 0;
                text-align: center;
                position: relative;
                z-index: 2;
                .icon{
                }
                .name{
                    margin-top:5px;
                }
                dd{
                    
                }
                
            }
        }

    }
}
</style>